Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

Vue.filter('slugify', function(value) {
    value = value.replace(/^\s+|\s+$/g, ''); // trim
    value = value.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
    var to   = "aaaaaeeeeeiiiiooooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
    value = value.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    value = value.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

    return value;
});

Vue.directive('date', {
    twoWay: true,
    bind: function () {
        var self = this;
        $(self.el).change(function() {
            var value = $(this).val();
            self.set(value);
        });
    },
    unbind: function () {
        var self = this;
    }
});

new Vue({
    el: '#events',
    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),
        filter: '',
        orderBy: 'name',
        sortBy: 'asc',
        view: 'list',
        total_delegates: 0,
        changingRoomingDelegate: false,
        search: {
            keywords: '',
            filter: '',
            date_id: '',
            client_id: '',
            status: ''
        },
        form: {
            client_id: '',
            name: '',
            private: 1,
            description: '',
            terms: '',
            template_id: 1,
            slug: '',
            status: 'Draft',
            cname: '',
            colour: '',
            secondary_colour: '',
            header_colour: '',
            footer: '',
            body_font: 'Open Sans',
            heading_font: 'Open Sans',
            registration_opens_at: '',
            registration_closes_at: '',
            updates_closes_at: ''
        },
        field_form: {
            options: {
                '1': '2',
                '3': '4',
                '5': '6',
            }
        },
        field_categories: [
            'General',
            'Menu',
            'Accommodation',
            'Transport',
            'Venue',
            'Questionnaire',
            'Guest',
        ],
        field_types: [
            { text: 'Text', value: 'text' },
            { text: 'Textarea', value: 'textarea' },
            { text: 'Select', value: 'select' },
            { text: 'Multiselect', value: 'multiselect' },
            { text: 'Checkbox', value: 'checkbox' },
            { text: 'Radio', value: 'radio' },
            { text: 'File upload', value: 'file' },
            { text: 'Date (dd/mm/yyyy)', value: 'date' },
            { text: 'Time (hh:mm)', value: 'time' },
            { text: 'Date of Birth (dd/mm/yyyy)', value: 'dob' },
            { text: 'Discount Codes', value: 'code' },
            { text: 'Label', value: 'label' },
            { text: 'Guests', value: 'guest' },
        ],
        field_grid: [
            { text: 'Half Column', value: 6},
            { text: 'Full Column', value: 12},
            { text: 'Third Column', value: 4},
            { text: 'Quarter Column', value: 3}
        ],
        events: [],
        event_form: null,
        recipient: '',
        users: [],
        contact_form: {
            id: '',
            event_id: null
        },
        delegate_form: {
            firstname: '',
            surname: '',
            email: '',
            event_id: null
        },
        delegate: null,
        delegate_upload: false,
        delegate_action: null,
        delegate_notify: false,
        activity_search: {
            from: '',
            to: ''
        },
        eventStatus: [
            'Draft',
            'Pre-event',
            'Post-event',
            'Archived'
        ],
        contentTypeList: [
            'Accommodation',
            'Agenda',
            'Countdown',
            'Dates',
            'Directory',
            'Download',
            'Feature',
            'General',
            'Image',
            'Intro',
            'Menu',
            'Photos',
            'Questionnaire',
            'Speakers',
            'Sponsors',
            'Transport',
            'Venue',
            'Video'
        ],
        reports: [],
        email_id: null,
        contentEditing: false,
        contentAdding: false,
        faqEditing: false,
        faqAdding: true,
        speakerEditing: false,
        speakerAdding: true,
        sponsorEditing: false,
        sponsorAdding: true,
        stepEditing: false,
        stepAdding: false,
        albumEditing: false,
        albumAdding: false,
        photo_upload: false,
        fieldEditing: false,
        fieldAdding: false,
        dateEditing: false,
        dateAdding: false,
        resetLink: null,
        errorBag: null
    },

    ready: function() {

        $.fn.datepicker.defaults.autoclose = true;
        $.fn.datepicker.defaults.daysOfWeekHighlighted = [0];
        $.fn.datepicker.defaults.startView = 'month';
        $.fn.datepicker.defaults.weekStart = 1;
        
        this.route();
        var self = this;
        window.onhashchange = function(e) {
            self.route();
        };
    },

    computed: {

        preEventContent: function() {
            return this.event.content.filter(function(e) {
                 return e.status == 'Pre-event';
            });
        },

        postEventContent: function() {
            return this.event.content.filter(function(e) {
                 return e.status == 'Post-event';
            });
        },

        selectDates: function () {
            var dates = this.event.dates.map(function (date) {
                return {
                    text: date.venue+' '+date.location+' - '+date.human_date,
                    value: date.id,
                }
            });

            return [{
                text: 'All',
                value: '',
            }].concat(dates);
        }

    },
 
    methods: {
        route: function() {
            // this.$set('loading.view', true);

            var hash = (window.location.hash.substr(1)).split('/');
            this.getClientList();

            // index
            if(! hash[0]) {
                this.view = 'list';
                this.getEvents();
                return;
            }

            // show
            if(hash.length === 1 && ! isNaN(hash[0])) {
                this.view = 'show';
                this.getEvent(hash[0]);
                this.sortableContent();
                this.sortableFaq();
                this.sortableSpeaker();
                this.sortableSponsor();
                // this.sortableFields();
                return;
            }

            // add
            if(hash.length === 1 && hash[0] == 'add') {
                this.getEventList();
                this.view = 'form';
                if(typeof this.errorBag !== 'undefined') {
                    this.errorBag = null;
                }
                $('.input-colorpicker').colorpicker({
                    format: 'hex',
                    color: '#000000',
                });
            }

            // edit
            if(hash.length === 2 && hash[1] == 'edit') {

                this.$http.get(this.api_url+'/events/'+hash[0], function(response) {
                    this.$set('event', response.event);
                    this.$set('editing', response.event);
                    this.editEvent(this.event);
                }).error((data, status, response) => {
                    console.log(response);
                });

            } else if(hash.length === 2)
            {
                this.view = 'show';
                this.getEvent(hash[0]);
                this.sortableContent();
                this.sortableFaq();
                // this.sortableFields();
                $('#content-tabs a[href="#'+hash[1]+'"]').tab('show');
                return;
            }
        },

        // Get events
        getEvents: function() {
            this.$set('loading.events', true); // show loading spinner
            this.$http.get(this.api_url+'/events?per_page=10', function(response) {
                this.loading.events = false; // remove loading spinner
                this.$set('events', response.events);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Get event list for select boxes
        getEventList: function() {
            this.$http.get(this.api_url+'/events/all', function(response) {
                var eventList = [];
                eventList.push({ text: 'Select event', value: '' });
                response.events.forEach(function(event) {
                    eventList.push({
                        text: event.name,
                        value: event.id
                    });
                });
                this.$set('eventList', eventList);
            }).error((data, status, response) => {
                console.log(response);
            });
        },

        // Get client list for select boxes
        getClientList: function() {
            this.$http.get(this.api_url+'/clients', function(response) {
                var clientList = [];
                clientList.push({ text: 'Select client', value: 0 });
                response.clients.forEach(function(client) {
                    clientList.push({
                        text: client.name,
                        value: client.id
                    });
                });
                this.$set('clientList', clientList);
            }).error((data, status, response) => {
                console.log(response);
            });
        },

        // add delegate to event
        addDelegate: function(e) {
            e.preventDefault();

            var delegate = this.delegate_form;
            delegate.event_id = this.event.id;
            this.$http.post(this.api_url+'/delegates/', delegate, function(response) {
                this.delegates.push(response.delegate);
                this.delegate_form = { firstname: '', surname: '', email: '', }
                $('#add_delegate').modal('hide');
            }).error((data, status, response) => {
                if(status == 422) {
                     this.$set('errorBag', data.errors);
                }
            });
        },

        // parse JSON
        parseJson: function(json) {
            return JSON.parse(json);
        },

        // update Content
        updateContent:  function(content) {
            this.$set('loading.updating_content', true); // show loading spinner

            // Prep content info for sending to API for updating.
            var content = {
                name: this.content.name,
                heading: this.content.heading,
                subheading: this.content.subheading,
                body: this.content.body,
                extra: this.content.extra,
                image: this.content.image,
                link: this.content.link,
                embed: this.content.embed,
                download: this.content.download,
                date: this.content.date,
                type: this.content.type,
                active: this.content.active,
                status: this.content.status,
            };


            // Add content.
            if(this.contentAdding)
            {

                // Update content.
                this.$http.post(this.api_url+'/events/'+this.event.id+'/content/', content, function(response) {

                    this.$set('content', response.content);

                    // if an image was specified; upload it and store it
                    var formData = new FormData();

                    formData.append('image', this.$$.contentImageInput.files[0]);

                    this.$http.post(this.api_url+'/events/'+this.event.id+'/content/'+this.content.id+'/upload', formData, function(image_response) {
                        this.$set('loading.updating_content', false); // remove loading spinner
                        this.$set('content_updated', response.message);
                        this.$set('errorBag', null);
                        this.$set('content', image_response.content);
                        this.$set('contentAdding', false);
                        this.$set('content', image_response.content);
                        $('.alert-dismissible').delay(2000).fadeOut();
                    }).error((data, status, response) => {
                        if(status == 422) {
                            this.$set('loading.updating_content', false); // hide loading spinner
                            this.$set('errorBag', data.errors);
                        }
                    });
                    
                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('loading.updating_content', false); // hide loading spinner
                        this.$set('errorBag', data.errors);
                    }
                });

            } else {

                // Update content.
                this.$http.put(this.api_url+'/events/'+this.event.id+'/content/'+this.content.id, content, function(response) {

                    this.$set('content', response.content);

                    // if an image was specified; upload it and store it
                    var formData = new FormData();

                    formData.append('image', this.$$.contentImageInput.files[0]);

                    this.$http.post(this.api_url+'/events/'+this.event.id+'/content/'+this.content.id+'/upload', formData, function(image_response) {
                        this.$set('loading.updating_content', false); // remove loading spinner
                        this.$set('content_updated', response.message);
                        this.$set('errorBag', null);
                        this.$set('content', image_response.content);
                        this.$set('contentEditing', false);
                        $('.alert-dismissible').delay(2000).fadeOut();
                    }).error((data, status, response) => {
                        if(status == 422) {
                            this.$set('loading.updating_content', false); // hide loading spinner
                            this.$set('errorBag', data.errors);
                        }
                    });
                    
                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('loading.updating_content', false); // hide loading spinner
                        this.$set('errorBag', data.errors);
                    }
                });

            }
        },

        getField: function(field) {
            this.$set('field', field);
        },

        // show form (clear any fields from previous)
        addField: function(step) {

            this.fieldEditing = false;
            this.fieldAdding = true;

            this.$set('step', step);
            
            this.$set('field', {
                owner_id: step.id,
                type: 'text',
                category: 'General',
                label: '',
                help: '',
                default: '',
                options: { '' : '' },
                options_values: { '' : '' },
                options_conditions: { '' : '' },
                validation: [],
                required: true,
                disabled: false,
                include: true,
                include_email: true,
                include_directory: false,
                break: false,
                grid: 6
            });

        },

        sortableFields: function() {
            thisIsThis = this;

            $.each(thisIsThis.event.steps, function( key, step ) {

                $('#step_'+step.id+' tbody').sortable({
                placeholder: "sort",
                handle: '.fa-bars',
                update: () => {
                    data = $('#step_'+step.id+' tbody').sortable('toArray','serialize');
                    thisIsThis.$http.post(thisIsThis.api_url+'/events/'+thisIsThis.event.id+'/steps/'+step.id+'/fields/sort', JSON.stringify(data), function(response) {
                    });
                }
                });

                $('#step_'+step.id+' tbody').disableSelection();

            });
        }, 

        addFieldOption: function() {
            var optionsBlock = $('.field-options-block');
            var newOption = $(".form-group", optionsBlock).eq(0).clone();
            $("input", newOption).val('');
            newOption.removeClass('clonable');
            newOption.appendTo(optionsBlock);
            
            var optionsValuesBlock = $('.field-options_values-block');
            var newOptionValue = $(".form-group", optionsValuesBlock).eq(0).clone();
            $("input", newOptionValue).val('');
            newOptionValue.removeClass('clonable');
            newOptionValue.appendTo(optionsValuesBlock);

            var optionsConditionsBlock = $('.field-options_conditions-block');
            var newOptionCondition = $(".form-group", optionsConditionsBlock).eq(0).clone();
            $("select", newOptionCondition).val('0');
            newOptionCondition.removeClass('clonable');
            newOptionCondition.appendTo(optionsConditionsBlock);
        },

        // editField
        editField: function(field) {

            this.$set('fieldAdding', false);
            this.$set('fieldEditing', true);
            this.$set('field', field);

        },

        // update Field
        updateField: function(field) {
            this.$set('loading.updating_field', true); // show loading spinner

            var fieldOptions = $('input[name="field-options[]"]:not(.clonable)').map(function(){ return $(this).val();}).get();
            var fieldOptionsValues = $('input[name="field-options_values[]"]:not(.clonable)').map(function(){ return $(this).val();}).get();
            var fieldOptionsConditions = $('select[name="field-options_conditions[]"]:not(.clonable)').map(function(){ return $(this).val();}).get();

            // Prep form field info for sending to API for updating.
            var form = {
                owner_id: this.field.owner_id,
                type: this.field.type,
                category: this.field.category,
                label: this.field.label,
                help: this.field.help,
                default: this.field.default,
                options: fieldOptions,
                options_values: fieldOptionsValues,
                options_conditions: fieldOptionsConditions,
                validation: this.field.validation,
                required: this.field.required,
                disabled: this.field.disabled,
                include: this.field.include,
                include_email: this.field.include_email,
                include_directory: this.field.include_directory,
                break: this.field.break,
                grid: this.field.grid,
            };
            
            // Add field.
            if(this.fieldAdding)
            {

                // Add field.
                this.$http.post(this.api_url+'/events/'+this.event.id+'/steps/'+form.owner_id+'/fields', form, function(response) {

                    window.location.replace('#'+this.event.id+'/registration');
                    window.location.reload();
                    
                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('loading.updating_field', false); // hide loading spinner
                        this.$set('errorBag', data.errors);
                    }
                });

            } else {

                // Update field.
                this.$http.put(this.api_url+'/events/'+this.event.id+'/steps/'+form.owner_id+'/fields/'+this.field.id, form, function(response) {
                    
                    window.location.replace('#'+this.event.id+'/registration');
                    window.location.reload();
                    
                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('loading.updating_field', false); // hide loading spinner
                        this.$set('errorBag', data.errors);
                    }
                });

            }
        },

        // remove field from a field
        removeField: function(field) {

            if( confirm("Are you sure you want to permanently remove this field?") == true ){
                this.$http.delete(this.api_url+'/events/'+this.event.id+'/steps/'+field.owner_id+'/fields/'+field.id, function(response) {
                    this.$set('event',response.event);
                }); 
                return true;
            } else {
                return false;
            }

        },

        // add Album
        addAlbum: function() {

            this.albumEditing = false;
            this.albumAdding = true;

            this.$set('album', {
                name: '',
                description: '',
            });

        },

        // toggleStepEditing
        editAlbum: function(album) {

            this.$set('albumAdding', false);
            this.$set('albumEditing', true);
            this.$set('album', album);

        },

        // update Album
        updateAlbum: function(album) {
            this.$set('loading.updating_album', true); // show loading spinner

            // Prep faq info for sending to API for updating.
            var album = {
                name: this.album.name,
                description: this.album.description,
            };

            // Add album.
            if(this.albumAdding)
            {

                // Add album.
                this.$http.post(this.api_url+'/events/'+this.event.id+'/albums/', album, function(response) {

                    // now upload photos 
                    var formData = new FormData();
                    formData.append('photos', this.$$.albumFileInput.files[0]);
                    this.$set('loading.photo_upload', true); // hide loading spinner

                    this.$http.post(this.api_url+'/events/'+this.event.id+'/albums/'+response.album.id+'/upload', formData, function(response) {
                        this.$set('loading.photo_upload', false); // hide loading spinner
                        this.$set('photo_upload_response', response.results);
                        $('.alert-dismissible').delay(2000).fadeOut();

                        window.location.replace('#'+this.event.id+'/albums');
                        window.location.reload();
                    }).error((data, status, response) => {
                        if(status == 422) {
                            this.$set('loading.updating_album', false); // hide loading spinner
                            this.$set('loading.photo_upload', false); // hide loading spinner
                            this.$set('errorBag', data.errors);
                        }
                    });
                    
                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('loading.updating_album', false); // hide loading spinner
                        this.$set('errorBag', data.errors);
                    }
                });

            } else {

                // Update album.
                this.$http.put(this.api_url+'/events/'+this.event.id+'/albums/'+this.album.id, album, function(response) {
                    
                    // now upload photos 
                    var formData = new FormData();
                    formData.append('photos', this.$$.albumFileInput.files[0]);
                    this.$set('loading.photo_upload', true); // hide loading spinner

                    this.$http.post(this.api_url+'/events/'+this.event.id+'/albums/'+response.album.id+'/upload', formData, function(response) {
                        this.$set('loading.photo_upload', false); // hide loading spinner
                        this.$set('photo_upload_response', response.results);
                        $('.alert-dismissible').delay(2000).fadeOut();
                    
                        window.location.replace('#'+this.event.id+'/albums');
                        window.location.reload();

                    }).error((data, status, response) => {
                        if(status == 422) {
                            this.$set('loading.updating_album', false); // hide loading spinner
                            this.$set('loading.photo_upload', false); // hide loading spinner
                            this.$set('errorBag', data.errors);
                        }
                    });
                    
                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('loading.updating_album', false); // hide loading spinner
                        this.$set('errorBag', data.errors);
                    }
                });

            }
        },
        
        viewAlbum: function(album) {

            this.$set('albumAdding', false);
            this.$set('albumEditing', false);
            this.$set('album', album);

        },

        // remove album from an event
        removeAlbum: function(album) {

            if( confirm("Are you sure you want to permanently remove this photo album?") == true ){
                this.$http.delete(this.api_url+'/events/'+this.event.id+'/albums/'+album.id, function(response) {
                    this.$set('event',response.event);
                }); 
                return true;
            } else {
                return false;
            }

        },

        // remove photo from an album
        removePhoto: function(photo) {

            if( confirm("Are you sure you want to permanently remove this photo?") == true ){
                this.$http.delete(this.api_url+'/events/'+this.event.id+'/photos/'+photo.id, function(response) {
                    this.$set('event', response.event);
                }); 
                return true;
            } else {
                return false;
            }

        },

        // add Step
        addStep: function() {

            this.stepEditing = false;
            this.stepAdding = true;

            this.$set('step', {
                name: '',
                number: '',
                description: '',
                include_email: true
            });

        },

        // toggleStepEditing
        editStep: function(step) {

            this.$set('stepAdding', false);
            this.$set('stepEditing', true);
            this.$set('step', step);

        },

        // update Step
        updateStep: function(step) {
            this.$set('loading.updating_step', true); // show loading spinner

            // Prep faq info for sending to API for updating.
            var step = {
                name: this.step.name,
                description: this.step.description,
                number: this.step.number,
                include_email: this.step.include_email,
            };


            // Add step.
            if(this.stepAdding)
            {

                // Add step.
                this.$http.post(this.api_url+'/events/'+this.event.id+'/steps/', step, function(response) {

                    window.location.replace('#'+this.event.id+'/registration');
                    window.location.reload();
                    
                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('loading.updating_step', false); // hide loading spinner
                        this.$set('errorBag', data.errors);
                    }
                });

            } else {

                // Update step.
                this.$http.put(this.api_url+'/events/'+this.event.id+'/steps/'+this.step.id, step, function(response) {
                    
                    window.location.replace('#'+this.event.id+'/registration');
                    window.location.reload();
                    
                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('loading.updating_step', false); // hide loading spinner
                        this.$set('errorBag', data.errors);
                    }
                });

            }
        },

        // remove step from an event
        removeStep: function(step) {

            if( confirm("Are you sure you want to permanently remove this step?") == true ){
                this.$http.delete(this.api_url+'/events/'+this.event.id+'/steps/'+step.id, function(response) {
                    this.$set('event',response.event);
                }); 
                return true;
            } else {
                return false;
            }

        },

        // show form (clear any fields from previous)
        showDelegateForm: function() {
            this.delegate_form = {
                firstname: '',
                surname: '',
                email: ''
            }
        },

        // upload delegates via csv
        uploadDelegates: function(e) {
            e.preventDefault();
            this.$set('loading.delegate_upload', true); // show loading spinner

            var formData = new FormData();
            formData.append('csv', this.$$.fileInput.files[0]);

            this.$http.post(this.api_url+'/delegates/upload/'+this.event.id, formData, function(response) {
                this.$set('loading.delegate_upload', false); // hide loading spinner
                this.$set('delegate_upload_response', response.results);
                $('.alert-dismissible').delay(2000).fadeOut();
                this.getDelegates(); // reload delegates
            }).error((data, status, response) => {
                if(status == 422) {
                    this.$set('loading.delegate_upload', false); // hide loading spinner
                    this.$set('errorBag', data.errors);
                }
            });
        },

        uploadDelegatePhoto: function(e) {
            e.preventDefault();
            this.$set('loading.delegate', true); // show loading spinner

            /* now upload image */
            var formData = new FormData();

            formData.append('photo', this.$$.delegatePhotoInput.files[0]);

            this.$http.post(this.api_url+'/delegates/'+this.delegate.id+'/profile/upload', formData, function(response) {
                this.$set('loading.delegate', false); // hide loading spinner
                this.delegate = response.event.delegate;
            }).error((data, status, response) => {
                if(status == 422) {
                    this.$set('loading.delegate', false); // hide loading spinner
                    this.$set('errorBag', data.errors);
                }
            });
        },

        // upload images/logos and save event settings
        saveSettings: function(e) {
            e.preventDefault();
            this.$set('loading.settings', true); // show loading spinner

            var formData = {
                colour: this.event.settings.colour,
                secondary_colour: this.event.settings.secondary_colour,
                header_colour: this.event.settings.header_colour,
                footer: this.event.settings.footer,
                body_font: this.event.settings.body_font,
                heading_font: this.event.settings.heading_font
            };

            /* save settings */
            this.$http.put(this.api_url+'/events/'+this.event.id+'/settings', formData, function(response) {
                
                /* now upload images */
                var formData = new FormData();

                formData.append('logo', this.$$.logoFileInput.files[0]);
                formData.append('banner', this.$$.bannerFileInput.files[0]);
                formData.append('bg-intro', this.$$.bgIntroFileInput.files[0]);
                formData.append('email_header_image', this.$$.headerFileInput.files[0]);
                //formData.append('footer', this.$$.footerFileInput.files[0]);

                this.$http.post(this.api_url+'/events/upload/'+this.event.id, formData, function(response) {
                    this.$set('loading.settings', false); // hide loading spinner
                    $('.alert-dismissible').delay(2000).fadeOut();
                    this.event.settings = response.event.settings;
                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('loading.settings', false); // hide loading spinner
                        this.$set('errorBag', data.errors);
                    }
                });

            }).error((data, status, response) => {
                if(status == 422) {
                    this.$set('loading.settings', false); // hide loading spinner
                    this.$set('errorBag', data.errors);
                }
            });
        },

        removeImages: function(type) {

            if( confirm("Are you sure you want to permanently delete the " + type + " image(s) for this event?") == true ){
                this.$http.delete(this.api_url+'/events/'+this.event.id+'/remove/'+type, function(response) {
                    this.$set('event', response.event);
                }); 
                return true;
            } else {
                return false;
            }

        },

        // Pagination
        page: function(dir) {
            this.$set('loading.events', true); // show loading spinner

            if(dir === 'prev' || dir === 'next') {
                var url = dir === 'prev' ? this.pagination.prev_page_url : this.pagination.next_page_url;
            } else {
                var url = dir;
            }

            url += '&orderBy=' + this.orderBy + '&sortBy=' + this.sortBy;
            if(parseInt(this.search.client_id)) url += '&client_id='+this.search.client_id;
            if(this.search.keywords) url += '&search='+this.search.keywords;
            if(this.search.status) url += '&status='+this.search.status;

            this.$http.get(url, function(response) {
                this.loading.events = false; // remove loading spinner
                this.$set('events', response.events);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Sort
        sort: function(orderBy) {
            this.$set('loading.events', true); // show loading spinner

            url = this.api_url+'/events?per_page=10';

            if(parseInt(this.search.client_id)) url += '&client_id='+this.search.client_id;
            if(this.search.keywords) url += '&search='+this.search.keywords;
            if(this.search.status) url += '&status='+this.search.status;


            this.sortBy = (this.orderBy == orderBy) ? 'desc' : 'asc';
            this.orderBy = orderBy;

            url += '&orderBy=' + orderBy + '&sortBy=' + this.sortBy;

            this.$http.get(url, function(response) {
                this.loading.events = false; // remove loading spinner
                this.$set('events', response.events);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Search events
        searchEvents: function(e) {
            e.preventDefault();

            this.$set('loading.events', true); // show loading spinner

            var url = this.api_url+'/events?per_page=10';

            if(parseInt(this.search.client_id)) url += '&client_id='+this.search.client_id;
            if(this.search.keywords) url += '&search='+this.search.keywords;
            if(this.search.status) url += '&status='+this.search.status;

            this.$http.get(url, function(response) {
                this.loading.events = false; // remove loading spinner
                this.events = response.events;
                if(typeof response.pagination !== 'undefined') {
                    this.pagination = response.pagination;
                }
            }).error((data, status, response) => {
                console.log(response);
            });
        },

        // Clear search
        clearSearch: function() {
            this.$set('loading.events', true); // show loading spinner
            this.$http.get(this.api_url+'/events?per_page=10', function(response) {
                this.loading.events = false; // remove loading spinner
                this.$set('events', response.events);
                this.search = {
                    client_id: 0,
                    keywords: '',
                    status: ''
                };
                if(typeof response.pagination !== 'undefined') {
                    this.pagination = response.pagination;
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Show an event's details
        getEvent: function(id,e) {
            if(e) e.preventDefault();
            this.$set('loading.events', true); // show loading spinner
            this.$http.get(this.api_url+'/events/'+id+'', function(response) {
                this.loading.events = false; // remove loading spinner
                this.view = 'show'; // show event
                this.$set('event', response.event);
                this.getEventEmails();
                this.getEventSmsMessages();
                this.getDelegates();
                this.getBreakdown();
                this.getActivity();
                this.getUsers();
                this.$nextTick(function () {
                    this.sortableFields();
                });
                
                /*
                $('.input-colorpicker').colorpicker({
                    format: 'hex',
                    color: response.event.settings.colour,
                }).on('changeColor.colorpicker', function(event) {
                    $('.sample-heading').css('color', event.color.toHex());
                    $('.sample-bg').css('background-color',event.color.toHex());
                }).on('hidePicker.colorpicker', function(event) {
                    $('.sample-heading').css('color', event.color.toHex());
                    $('.sample-bg').css('background-color',event.color.toHex());
                });*/

                $.fn.datepicker.defaults.autoclose = true;
                $.fn.datepicker.defaults.startView = 'month';
                $.fn.datepicker.defaults.weekStart = 1;

            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        primeColorPickers: function() {

            theEvent = this.event;

            $('.input-colorpicker-p').colorpicker({
                format: 'hex',
                color: (this.event.settings.colour) ? this.event.settings.colour : '#333333',
            }).on('changeColor.colorpicker', function(event) {
                $('.sample-heading').css('color', event.color.toHex());
                $('.sample-bg').css('background-color',event.color.toHex());
                theEvent.settings.colour = event.color.toHex();
            }).on('hidePicker.colorpicker', function(event) {
                $('.sample-heading').css('color', event.color.toHex());
                $('.sample-bg').css('background-color',event.color.toHex());
                theEvent.settings.colour = event.color.toHex();
            });

            $('.input-colorpicker-s').colorpicker({
                format: 'hex',
                color: (this.event.settings.secondary_colour) ? this.event.settings.secondary_colour : '#333333',
            }).on('changeColor.colorpicker', function(event) {
                $('.sample-secondary-border').css('border-color', event.color.toHex());
                $('.sample-secondary-bg').css('background-color',event.color.toHex());
                theEvent.settings.secondary_colour = event.color.toHex();
            }).on('hidePicker.colorpicker', function(event) {
                $('.sample-secondary-border').css('border-color', event.color.toHex());
                $('.sample-secondary-bg').css('background-color',event.color.toHex());
                theEvent.settings.secondary_colour = event.color.toHex();
            });

            $('.input-colorpicker-h').colorpicker({
                format: 'hex',
                color: (this.event.settings.header_colour) ? this.event.settings.header_colour : '#333333',
            }).on('changeColor.colorpicker', function(event) {
                $('.sample-header-border').css('border-color', event.color.toHex());
                $('.sample-header-bg').css('background-color',event.color.toHex());
                theEvent.settings.header_colour = event.color.toHex();
            }).on('hidePicker.colorpicker', function(event) {
                $('.sample-header-border').css('border-color', event.color.toHex());
                $('.sample-header-bg').css('background-color',event.color.toHex());
                theEvent.settings.header_colour = event.color.toHex();
            });

            $('.input-colorpicker-f').colorpicker({
                format: 'hex',
                color: (this.event.settings.footer) ? this.event.settings.footer : '#333333',
            }).on('changeColor.colorpicker', function(event) {
                $('.sample-footer-bg').css('background-color',event.color.toHex());
                theEvent.settings.footer = event.color.toHex();
            }).on('hidePicker.colorpicker', function(event) {
                $('.sample-footer-bg').css('background-color',event.color.toHex());
                theEvent.settings.footer = event.color.toHex();
            });
        },

        // Get event emails
        getUsers: function() {
            this.$http.get(this.api_url+'/events/'+this.event.id+'/users', function(response) {
                this.$set('users', response.users);
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Get event emails
        getEventEmails: function() {
            this.$set('loading.event_emails', true); // show loading spinner
            this.$http.get(this.api_url+'/events/'+this.event.id+'/emails', function(response) {
                this.loading.event_emails = false; // remove loading spinner
                this.$set('event_emails', response.emails);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Get event SMS messages
        getEventSmsMessages: function() {
            this.$set('loading.event_sms_messages', true);
            this.$http.get(this.api_url+'/events/'+this.event.id+'/sms', function(response) {
                this.loading.event_sms_messages = false; // remove loading spinner
                this.$set('event_sms_messages', response.sms);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Get delegates (optional search filters)
        getDelegates: function() {

            this.loading.delegates = true; // show loading spinner

            var url = this.api_url+'/events/'+this.event.id+'/delegates?per_page=25';
            if(this.search.keywords) url += '&search='+this.search.keywords;
            if(this.search.filter) url += '&filter='+this.search.filter;
            if(this.search.date_id) url += '&date_id='+this.search.date_id;

            this.$http.get(url, function(response) {
                this.loading.delegates = false;
                this.$set('delegates', response.delegates);
                this.$set('total_delegates', response.total_delegates);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('delegate_pagination', response.pagination);
                }
            }).error((data, status, response) => {
                console.log(response);
            });

        },

        // Pagination for delegates
        pageDelegates: function(dir, e) {
            if(e) e.preventDefault();

            this.loading.delegates = true; // show loading spinner

            if(dir === 'prev' || dir === 'next') {
                var url = dir === 'prev' ? this.delegate_pagination.prev_page_url : this.delegate_pagination.next_page_url;
            } else {
                var url = dir;
            }

            this.$http.get(url, function(response) {
                this.loading.delegates = false;
                this.$set('delegates', response.delegates);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('delegate_pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Clear delegate search
        clearDelegateSearch: function(e) {
            if(e) e.preventDefault();
            this.loading.delegates = true; // show loading spinner

            var url = this.api_url+'/events/'+this.event.id+'/delegates?per_page=25';

            this.$http.get(url, function(response) {
                this.loading.delegates = false;
                this.search = {
                    keywords: '',
                    filter: '',
                    date_id: ''
                };
                this.$set('delegates', response.delegates);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('delegate_pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // add contact to event
        addContact: function(user) {

            this.$http.put(this.api_url+'/events/'+this.event.id+'/contacts/'+user.id, function(response) {
                $('.alert-dismissible').delay(2000).fadeOut();
                this.event.contacts.push(response.contact);
            }).error((data, status, response) => {
                if(status == 422) {
                     this.$set('errorBag', data.errors);
                }
            });

        },

        // add contact to event
        toggleContactField: function(user, field, value) {

            formData = {
                field: field,
                value: value
            }

            this.$http.put(this.api_url+'/events/'+this.event.id+'/contacts/'+user.id+'/toggle', formData, function(response) {
                this.$set('event.contacts',response.contacts);
            });

        },

        // remove contact from the event
        removeContact: function(user) {

            this.$http.delete(this.api_url+'/events/'+this.event.id+'/contacts/'+user.id, function(response) {
                this.$set('event',response.event);
            });

        },

        // Get event email preview
        getEventEmailPreview: function(email) {
            this.$http.get(email.preview, function(html) {
                email.html = html;
            }).error(function (data, status, request) {
                console.log(request);
            });
            this.$set('email', email);
            this.$set('preview_sent', null);
        },

        // Send event email preview
        sendEventEmailPreview: function(email,e) {
            if(e) e.preventDefault();
            request = {
                email: this.recipient,
            };
            this.$http.post(email.preview, request, function(response)  {
                this.$set('preview_sent', response.message);
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Get password reset link event email preview
        getPasswordResetLink: function(e) {
            if(e) e.preventDefault();
            this.$set('loading.getting_link', true);
            this.$set('reset_link', null);
            this.$http.get(this.api_url+'/delegates/'+this.delegate.id+'/password/reset', function(response) {
                this.loading.getting_link = false; // remove loading spinner
                this.$set('reset_link', response.reset_link);
                this.$set('errorBag', null);
            }).error((data, status, response) => {
                if(status == 422) {
                    this.$set('reset_link', null);
                    this.$set('loading.getting_link', false); // hide loading spinner
                    this.$set('errorBag', data.errors);
                }
            });
        },

        // edit event
        editEvent: function(event) {

            this.view = 'form';
            if(typeof this.errorBag !== 'undefined') {
                this.errorBag = null;
            }
            
            this.$set('form', event);
            this.$set('editing', event);            
        },

        // add SMS
        addEventSms: function() {
            this.smsEditing = false;
            this.smsAdding = true;
            
            this.$set('sms', {
                name: '',
                content: '',
                link: false,
                from: '',
                scope: 'all',
                scope_qualifier: null,
            });
        },

        // update SMS
        editEventSms: function(sms) {
            this.smsEditing = true;
            this.smsAdding = false;
            
            this.$set('sms', {
                id: sms.id,
                name: sms.name,
                content: sms.content,
                link: sms.link,
                from: sms.from,
                scope: sms.scope,
                scope_qualifier: sms.scope_qualifier,
            });
        },

        // Get event email
        getEventSms: function(sms) {
            this.$set('loading.sms', true);
            this.$http.get(this.api_url+'/events/'+this.event.id+'/sms/'+sms.id, function(response)  {
                this.$set('previewPhone', '');
                this.$set('sms', sms);
                this.$set('sms_recipients', response.valid_recipients);
                this.$set('sms_recipients_count', response.valid_recipients_count);
                this.$set('sms_total_recipients', response.potential_recipients);
                this.$set('sms_missing_recipients', response.missing_recipients);
                this.$set('sms_scope', response.scope);
                this.$set('sms_scope_qualifier', response.scope_qualifier);
                this.loading.sms = false; // remove loading spinner
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        updateSmsImage: function (sms) {
            var formData = new FormData();
            formData.append('image', this.$$.smsImageFileInput.files[0]);

            this.$http.post(this.api_url+'/events/'+this.event.id+'/sms/'+sms.id, formData, function(response) {
                this.$set('loading.save_sms_image', false); // hide loading spinner
                $('.alert-dismissible').delay(2000).fadeOut();
                sms.image = response.sms.image;
                this.$set('imageErrorBag', false);
            }).error((data, status, response) => {
                if(status == 422) {
                    this.$set('loading.save_sms_image', false); // hide loading spinner
                    this.$set('imageErrorBag', data.errors);
                }
            });
        },

        removeSmsImage: function(sms) {
            if(window.confirm('Are you sure you want to remove this image?')) {
                this.$http.delete(this.api_url+'/events/'+this.event.id+'/sms/'+sms.id+'/image', {}, function(response) {
                    this.$set('loading.save_sms_image', false); // hide loading spinner
                    $('.alert-dismissible').delay(2000).fadeOut();
                    sms.image = response.sms.image;
                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('loading.save_sms_image', false); // hide loading spinner
                        this.$set('errorBag', data.errors);
                    }
                });
            }
        },

        // save SMS settings
        saveSmsSettings:  function(content) {

            this.$set('loading.updating_sms', true); // show loading spinner

            // Prep content info for sending to API for updating.
            var content = {
                name: this.sms.name,
                content: this.sms.content,
                link: this.sms.link,
                from: this.sms.from,
                scope: this.sms.scope,
                scope_qualifier: this.sms.scope_qualifier,
            };

            // Add content.
            if(this.smsAdding)
            {
                // Update content.
                this.$http.post(this.api_url+'/events/'+this.event.id+'/sms/', content, function(response) {

                    /*this.$set('email', response.email);
                    this.$set('loading.updating_email', false); // remove loading spinner
                    this.$set('email_updated', response.message);
                    this.$set('errorBag', null);
                    this.$set('contentAdding', false);*/

                    window.location.replace('#'+this.event.id+'/sms');
                    window.location.reload();

                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('loading.updating_sms', false); // hide loading spinner
                        this.$set('errorBag', data.errors);
                    }
                });

            } else {

                // Update content.
                this.$http.put(this.api_url+'/events/'+this.event.id+'/sms/'+this.sms.id, content, function(response) {

                    /*this.$set('loading.updating_email', false); // remove loading spinner
                    this.$set('email_updaed', response.message);
                    this.$set('errorBag', false);
                    this.$set('email', response.email);
                    this.$set('updating.email', false);*/

                    window.location.replace('#'+this.event.id+'/sms');
                    window.location.reload();

                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('loading.updating_sms', false); // hide loading spinner
                        this.$set('errorBag', data.errors);
                    }
                });

            }

        },

        removeSmsMessage: function(sms) {
            if( confirm("Are you sure you want to permanently remove this SMS message?") == true ){
                this.$http.delete(this.api_url+'/events/'+this.event.id+'/sms/'+sms.id, function(response) {
                    this.$set('event_sms_messages', response.sms);
                });
                return true;
            } else {
                return false;
            }
        },

        // Send SMS preview
        smsSmsPreview(sms) {
            this.$set('loading.sending_preview', true);
            var data = {phone: this.previewPhone};
            this.$http.post(this.api_url+'/events/'+this.event.id+'/sms/'+sms.id+'/preview', data, function(response)  {
                this.$set('sms_preview_sent', response.message);
                this.loading.sending_preview = false; // hide loading spinner
                this.previewPhone = null;
                this.$set('errorBag', null);
            }).error(function (data, status, request) {
                if(status == 422) {
                    this.$set('loading.sending_preview', false); // hide loading spinner
                    this.$set('errorBag', data.errors);
                }
            });
        },

        // Send event SMS
        sendSms: function(sms) {
            this.$set('loading.sending', true);
            this.$http.post(this.api_url+'/events/'+this.event.id+'/sms/'+sms.id+'/send/', function(response)  {
                this.$set('event_sms_sent', response.message);
                sms.sent = response.sms.sent;
                sms.sent_at = response.sms.sent_at;
                sms.sent_at_human = response.sms.sent_at_human;
                sms.recipients = response.sms.recipients;
                this.loading.sending = false; // remove loading spinner
                this.$set('errorBag', null);
            }).error(function (data, status, request) {
                if(status == 422) {
                    this.$set('loading.sending', false); // hide loading spinner
                    this.$set('errorBag', data.errors);
                }
            });
        },

        // add content
        addEventEmail: function() {

            this.emailEditing = false;
            this.emailAdding = true;

            this.$set('email', {
                subject: '',
                title: '',
                content: '',
                scope: 'all',
                event_date_id: '',
                signoff: '',
                extras: true,
                event_details: false,
                email_id: 12
            });

            theEmail = this.email;

            $('#add-email-content').summernote({
                height: 280,
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph','link']],
                    ['view', ['codeview']]
                ]
            }).on('summernote.change', function(we, contents, $editable) {
                theEmail.content = contents;
            });
        },

        // save email settings
        saveEmailSettings:  function(content) {

            this.$set('loading.updating_email', true); // show loading spinner

            // Prep content info for sending to API for updating.
            var content = {
                subject: this.email.subject,
                title: this.email.title,
                content: this.email.content,
                scope: this.email.scope,
                event_date_id: (this.email.scope == 'attending' || this.email.scope == 'attendingNoPhoto') ? this.email.event_date_id : '',
                signoff: this.email.signoff,
                extras: this.email.extras,
                email_id: this.email.email_id,
                event_details: this.email.event_details
            };

            // Add content.
            if(this.emailAdding)
            {
                // Update content.
                this.$http.post(this.api_url+'/events/'+this.event.id+'/emails', content, function(response) {

                    /*this.$set('email', response.email);
                    this.$set('loading.updating_email', false); // remove loading spinner
                    this.$set('email_updated', response.message);
                    this.$set('errorBag', null);
                    this.$set('contentAdding', false);*/

                    window.location.replace('#'+this.event.id+'/emails');
                    window.location.reload();

                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('loading.updating_email', false); // hide loading spinner
                        this.$set('errorBag', data.errors);
                    }
                });

            } else {

                // Update content.
                this.$http.put(this.api_url+'/events/'+this.event.id+'/emails/'+this.email.event_email_id, content, function(response) {

                    /*this.$set('loading.updating_email', false); // remove loading spinner
                    this.$set('email_updaed', response.message);
                    this.$set('errorBag', false);
                    this.$set('email', response.email);
                    this.$set('updating.email', false);*/

                    window.location.replace('#'+this.event.id+'/emails');
                    window.location.reload();

                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('loading.updating_email', false); // hide loading spinner
                        this.$set('errorBag', data.errors);
                    }
                });

            }

        },

        // remove email from the event
        removeEmail: function(email) {

            if( confirm("Are you sure you want to permanently remove this email?") == true ){
                this.$http.delete(this.api_url+'/events/'+this.event.id+'/emails/'+email.event_email_id, function(response) {
                    this.$set('event_emails',response.emails);
                });
                return true;
            } else {
                return false;
            }

        },

        clearPreviewSent: function() {
            this.preview_sent = false;
        },

        // Get event email
        getEventEmail: function(email) {
            this.$set('loading.email', true);
            this.$set('updating.email', false);
            this.$http.get(this.api_url+'/events/'+this.event.id+'/emails/'+email.event_email_id, function(response)  {
                this.$set('email', email);
                this.$set('email_recipients', response.recipients);
                this.$set('email_scope', response.scope);
                this.$set('email_date', response.date);
                this.loading.email = false; // remove loading spinner
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        toggleEmailSettings: function(swtch) {
            this.$set('updating.email', swtch);

            theEmail = this.email;

            $('#email-content').summernote({
                height: 280,
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph','link']],
                    ['view', ['codeview']]
                ]
            }).on('summernote.change', function(we, contents, $editable) {
                theEmail.content = contents;
            });
        },

        // Send event email preview
        sendEventEmail: function() {
            event_email_id = this.event_email_id;
            this.$set('loading.sending', true);
            this.$http.get(this.api_url+'/events/'+this.event.id+'/emails/'+event_email_id+'/send/'+this.delegate.id, function(response)  {
                this.$set('event_email_sent', response.message);
                this.loading.sending = false; // remove loading spinner
                this.$set('errorBag', null);
            }).error(function (data, status, request) {
                if(status == 422) {
                    this.$set('loading.sending', false); // hide loading spinner
                    this.$set('errorBag', data.errors);
                }
            });
        },

        // Send event email
        sendEmail: function(email) {
            
            this.$set('loading.sending', true);
            this.$http.get(this.api_url+'/events/'+this.event.id+'/emails/'+email.event_email_id+'/send/', function(response)  {
                this.$set('event_email_sent', response.message);
                this.loading.sending = false; // remove loading spinner
                this.$set('errorBag', null);
            }).error(function (data, status, request) {
                if(status == 422) {
                    this.$set('loading.sending', false); // hide loading spinner
                    this.$set('errorBag', data.errors);
                }
            });
        },

        // Send event email preview
        resendEventEmail: function(event_email_id) {
            this.$set('loading.sending', true);
            this.$http.get(this.api_url+'/events/'+this.event.id+'/emails/'+event_email_id+'/resend/', function(response)  {
                this.$set('event_email_sent', response.message);
                this.loading.sending = false; // remove loading spinner
                this.$set('errorBag', null);
            }).error(function (data, status, request) {
                if(status == 422) {
                    this.$set('loading.sending', false); // hide loading spinner
                    this.$set('errorBag', data.errors);
                }
            });
        },

        // Get form info
        getEventForm: function(event_form) {
            this.$set('loading.event_form', true); // show loading spinner
            this.loading.event_form = false; // remove loading spinner
            this.$set('event_form', event_form);
        },

        // Get event delegate info
        getDelegate: function(delegate_id) {
            this.$set('loading.delegate', true); // show loading spinner
            this.$set('loading.delegate_activity', true); // show loading spinner
            this.$set('loading.delegate_email_activity', true); // show loading spinner
            this.$http.get(this.api_url+'/events/'+this.event.id+'/delegates/'+delegate_id, function(response) {
                this.loading.delegate = false; // remove loading spinner
                this.$set('delegate', response.delegate);
                this.getDelegatePayments(response.delegate.id);
                this.getDelegateActivity(response.delegate.id);
                this.getDelegateEmailActivity(response.delegate.id);
                this.getDelegateSmsActivity(response.delegate.id);
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Get delegate payments
        getDelegatePayments: function(delegate_id) {
            this.$set('loading.payments', true); // show loading spinner
            this.$http.get(this.api_url+'/delegates/payments?delegate_id='+delegate_id, function(response) {
                this.loading.payments = false; // remove loading spinner
                this.$set('payments', response.payments);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                    this.$nextTick(() => {
                      $('[data-toggle="popover"]').popover({'trigger': 'hover'});
                    });
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Get delegate activity
        getDelegateActivity: function(delegate_id) {
            this.$set('loading.delegate_activity', true); // show loading spinner
            this.$http.get(this.api_url+'/delegates/activity?delegate_id='+delegate_id, function(response) {
                this.loading.delegate_activity = false; // remove loading spinner
                this.$set('delegate_activity', response.activity);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Get delegate email activity
        getDelegateEmailActivity: function(delegate_id) {
            this.$set('loading.delegate_email_activity', true); // show loading spinner
            this.$http.get(this.api_url+'/emails/activity?delegate_id='+delegate_id, function(response) {
                this.loading.delegate_email_activity = false; // remove loading spinner
                this.$set('delegate_email_activity', response.activity);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Get delegate SMS activity
        getDelegateSmsActivity: function(delegate_id) {
            this.$set('loading.delegate_sms_activity', true); // show loading spinner
            this.$http.get(this.api_url+'/sms/activity?delegate_id='+delegate_id, function(response) {
                this.loading.delegate_sms_activity = false; // remove loading spinner
                this.$set('delegate_sms_activity', response.activity);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        updateDelegate:  function() {
            this.$set('loading.updating_delegate', true); // show loading spinner

            var errors = [];

            // Prep delegate info for sending to API for updating.
            var delegate = {
                title: this.delegate.title,
                firstname: this.delegate.firstname,
                surname: this.delegate.surname,
                gender: this.delegate.gender,
                email: this.delegate.email,
                phone: this.delegate.phone,
                department: this.delegate.department,
                position: this.delegate.position
            };

            if(!delegate.firstname) errors.push('Please enter this delegate\'s first name');
            if(!delegate.surname) errors.push('Please enter this delegate\'s surname');
            if(!delegate.email) errors.push('Please enter this delegate\'s email address');

            if(errors.length > 0)
            {
                this.$set('loading.updating_delegate', false); // hide loading spinner
                this.$set('errorBag', errors);
            } else {

                // Update delegate.
                this.$http.put(this.api_url+'/delegates/'+this.delegate.id, delegate, function(response) {
                    this.loading.updating_delegate = false; // remove loading spinner
                    this.$set('delegate_updated', response.message);
                    this.$set('errorBag', null);
                    window.location.replace('#'+this.event.id+'/delegates');
                    window.location.reload();
                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('loading.updating_delegate', false); // hide loading spinner
                        this.$set('errorBag', data.errors);
                    }
                });

            }
        },

        selectRoomingOption: function(delegate) {
            this.changingRoomingDelegate = false;
            this.delegate.rooming_delegate_id = delegate.id;
            this.delegate.rooming_delegate.id = delegate.id;
            this.delegate.rooming_delegate.name = delegate.fullname;
        },

        toggleChangeRoomingDelegate: function() {
            this.changingRoomingDelegate = true;
        },

        toggleDelegateRegistration: function() {
            this.delegate_registration_updated = false;
        },

        updateDelegateRegistration: function(e) {
            e.preventDefault();

            this.$set('loading.updating_delegate_registration', true); // show loading spinner

            // Prep delegate event preferences for sending to API.
            var field_responses = {};
            var errors = [];

            var eventPrefs = {
                notify: this.delegate_notify,
                attending: this.delegate.attending,
                date_id: this.delegate.date_id,
                dietary_requirements: this.delegate.dietary_requirements,
                accommodation: this.delegate.accommodation,
                rooming_delegate_id: this.delegate.rooming_delegate_id,
                additional_information: this.delegate.additional_information,
                field_responses: field_responses
            };

            if(this.delegate.attending == 1)
            {
                // only check for event date if there are any
                if(!eventPrefs.date_id && this.event.dates.length) {
                    errors.push('Please select an event Venue/Date/Location');
                    $('select[name=date]').parent('.form-group').addClass('has-error');
                } else {
                    $('select[name=date]').parent('.form-group').removeClass('has-error');
                }

                // SNAG - soon to be obsolete
                $.each(this.event.fields, function( key, field ) {

                    input = $('field_'+field.type+'[name='+field.name+']');
                    form_group = input.parent('.form-group');

                    value = (field.type == 'select') ? $(field.type+'[name=field_'+field.name+'] option:selected').val() : (field.type == 'text') ? $('input[name=field_'+field.name+']').val() : $(field.type+'[name=field_'+field.name+']').val();

                    field_responses[field.name] = value;
                    form_group.removeClass('has-error');

                    if(field.required && !value || field.required && value == '') {
                      errors.push(field.label+' is required.');
                      form_group.addClass('has-error');
                    }

                });

                $.each(this.event.steps, function( index, form_step ) {
                    $.each(form_step.fields, function( index, field ) {
                        switch(field.type)
                        {
                            case 'text':
                                if(field.required && !$('input[name=field_'+field.id+']').val()) errors.push(field.label + ' is required.');
                                break;

                             case 'textarea':
                                if(field.required && !$('textarea[name=field_'+field.id+']').val()) errors.push(field.label + ' is required.');
                                break;

                            case 'select':
                                if(field.required && !$('select[name=field_'+field.id+']').val()) errors.push(field.label + ' is required.');
                                 break;

                            case 'multiselect':
                                if(field.required && !$('select[name=field_'+field.id+']').val()) errors.push(field.label + ' is required.');
                                 break;

                            case 'radio':
                                if(field.required && !$('input[name=field_'+field.id+']:checked').val()) errors.push(field.label + ' is required.');
                                break;

                            case 'checkbox':
                                if(field.required && !$('input[name=field_'+field.id+']:checked').val()) errors.push(field.label + ' is required.');
                                break;

                            case 'score':
                                if(field.required && !$('input[name=field_'+field.id+']:checked').val()) errors.push(field.label + ' score required.');
                                break;

                            case 'multiscore':
                                var i = 0;
                                $.each(field.options, function(value, score)
                                {
                                    if(field.required && !$('input[name=field_'+field.id+'_'+i+']:checked').val()) errors.push(field.label + ' (' + value + ') score required.');
                                    i++;
                                });
                                break;
                        }
                    });
                });

                $.each(this.event.steps, function( index, step ) {
                    $.each(step.fields, function( index, field ) {
                        switch(field.type)
                        {
                            case 'text':
                                field_responses[field.name] = $('input[name=field_'+field.id+']').val();
                                break;

                             case 'textarea':
                                field_responses[field.name] = $('textarea[name=field_'+field.id+']').val();
                                break;

                            case 'select':
                                field_responses[field.name] = $('select[name=field_'+field.id+']').val();
                                break;

                            case 'multiselect':
                                field_responses[field.name] = $('select[name=field_'+field.id+']').val();
                                break;

                            case 'radio':
                                field_responses[field.name] = $('input[name=field_'+field.id+']:checked').val();
                                break;

                            case 'checkbox':
                                field_responses[field.name] = $('input[name=field_'+field.id+']:checked').val();
                                break;

                            case 'score':
                                field_responses[field.name] = $('input[name=field_'+field.id+']:checked').val();
                                break;

                            case 'multiscore':
                                var i = 0;
                                field_responses[field.name] = [];
                                $.each(field.options, function(value, score)
                                {
                                    field_responses[field.name][i] = $('input[name=field_'+field.id+'_'+i+']:checked').val();
                                    i++;
                                });
                                break;
                        }
                    });
                });

            }

            eventPrefs.field_responses = field_responses;

            if(errors.length > 0)
            {
                this.$set('loading.updating_delegate_registration', false); // hide loading spinner
                this.$set('errorBag', errors);

            } else {
                // update delegate registration
                this.$http.put(this.api_url+'/events/'+this.event.id+'/delegates/'+this.delegate.id, eventPrefs, function(response) {
                    this.$set('delegate_registration_updated', response.message);
                    this.$set('loading.delegate_registration_updated', false); // hide loading spinner
                    this.$set('loading.updating_delegate_registration', false); // hide loading spinner
                    // get delegate now that we've updated this record
                    this.$set('delegate',response.delegate);
                    this.$set('delegate_notify', false);
                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('loading.updating_delegate_registration', false); // hide loading spinner
                        this.$set('errorBag', data.errors);
                    }
                });
            }
        },

        // delete delegate
        deleteDelegate:  function() {
            this.$set('loading.deleting_delegate', true); // show loading spinner

            // Delete delegate.
            this.$http.delete(this.api_url+'/events/'+this.event.id+'/delegates/'+this.delegate.id, function(response) {
                this.loading.deleting_delegate = false; // remove loading spinner
                this.$set('delegate_deleted', response.message);
                window.location.replace('#'+this.event.id+'/delegates');
                window.location.reload();
            }).error((data, status, response) => {
                if(status == 422) {
                    this.$set('loading.updating_delegate', false); // hide loading spinner
                    this.$set('errorBag', data.errors);
                }
            });
        },


        // Get event date info
        getDate: function(date) {
            this.dateEditing = false;
            this.$set('date', date);
        },

        // add date
        addEventDate: function() {

            this.dateEditing = false;
            this.dateAdding = true;

            this.$set('errorBag', null);

            this.$set('date', {
                venue: '',
                location: '',
                description: '',
                content: '',
                map: '',
                capacity: '',
                available: true,
                alert: false
            });
            
        },

        toggleDateEditing: function(swtch) {

            this.dateAdding = false;
            this.dateEditing = swtch;

        },

        // update Date
        updateDate:  function(date) {

            this.$set('loading.updating_date', true); // show loading spinner
            this.$set('errorBag', null);

            // Prep date info for sending to API for updating.
            var formData = {
                venue: this.date.venue,
                location: this.date.location,
                description: this.date.description,
                content: this.date.content,
                map: this.date.map,
                capacity: this.date.capacity,
                available: this.date.available,
                starts: this.date.start,
                finishes: this.date.finish,
                currency: this.date.currency,
            };

            if(this.dateAdding)
            {

                // Add date.
                this.$http.post(this.api_url+'/events/'+this.event.id+'/dates/', formData, function(response) {

                    this.$set('date', response.date);
                    this.$set('event', response.event);
                    this.$set('loading.updating_date', false); // remove loading spinner
                    this.$set('date_updated', response.message);
                    this.$set('errorBag', null);
                    this.$set('dateAdding', false);
                    
                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('loading.updating_date', false); // hide loading spinner
                        this.$set('errorBag', data.errors);
                    }
                });

            } else {

                // Update date.
                this.$http.put(this.api_url+'/events/'+this.event.id+'/dates/'+this.date.id, formData, function(response) {

                    this.$set('date', response.date);
                    this.$set('event', response.event);
                    this.$set('loading.updating_date', false); // remove loading spinner
                    this.$set('date_updated', response.message);
                    this.$set('errorBag', null);
                    this.$set('dateEditing', false);
                    $('.alert-dismissible').delay(2000).fadeOut();
                    
                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('loading.updating_date', false); // hide loading spinner
                        this.$set('errorBag', data.errors);
                    }
                });

            }

        },

        // remove date from the event
        removeDate: function(date) {

            if( confirm("Are you sure you want to permanently remove this date/location?") == true ){
                this.$http.delete(this.api_url+'/events/'+this.event.id+'/dates/'+date.id, function(response) {
                    this.$set('event', response.event);
                });
                return true;
            } else {
                return false;ov
            }

        },

        // Get event content info
        getContent: function(content) {
            this.contentEditing = false;
            this.$set('content', content);
        },

        sortableContent: function() {

          thisIsThis = this;

          $('#event_content tbody').sortable({
              placeholder: "sort",
              handle: '.fa-bars',
              update: () => {
                data = $('#event_content tbody').sortable('toArray','serialize');
                // Update order.
                thisIsThis.$http.post(thisIsThis.api_url+'/events/'+thisIsThis.event.id+'/content/sort', JSON.stringify(data), function(response) {
                    console.log(response);
                });
              }
            });

            $('#event_content tbody').disableSelection();
        },

        // add content
        addEventContent: function() {

            this.contentEditing = false;
            this.contentAdding = true;

            this.$set('content', {
                name: '',
                heading: '',
                subheading: '',
                type: 'General',
                status: 'Pre-event',
                body: '',
                embed: '',
                date: '',
                extra: '',
                agenda: [{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''}],
                image: '',
                active: true
            });

            theContent = this.content;

            this.$nextTick(function () {
                $('#content-body').summernote({
                    height: 350,
                    toolbar: [
                        ['style', ['bold', 'italic', 'underline', 'clear']],
                        ['fontsize', ['fontsize']],
                        ['color', ['color']],
                        ['para', ['ul', 'ol', 'paragraph','link']],
                        ['view', ['codeview']]
                    ],
                    onPaste: function (e) {
                        var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                        e.preventDefault();
                        document.execCommand('insertText', false, bufferText);
                    }
                }).on('summernote.change', function(we, contents, $editable) {
                    theContent.body = contents;
                });
    
                $('#content-extra').summernote({
                    height: 100,
                    toolbar: [
                        ['style', ['bold', 'italic', 'underline', 'clear']],
                        ['color', ['color']],
                        ['para', ['ul', 'ol', 'paragraph','link']],
                        ['view', ['codeview']]
                    ]
                }).on('summernote.change', function(we, contents, $editable) {
                    theContent.extra = contents;
                });

                for (var i = 0; i < 20; i++) {
                    $('#agenda-'+i).summernote({
                        height: 200,
                        toolbar: [
                            ['style', ['bold', 'italic', 'underline', 'clear']],
                            ['fontsize', ['fontsize']],
                            ['color', ['color']],
                            ['para', ['ul', 'ol', 'paragraph','link']],
                            ['view', ['codeview']]
                        ],
                        onPaste: function (e) {
                            var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                            e.preventDefault();
                            document.execCommand('insertText', false, bufferText);
                        }
                    }).on('summernote.change', function(we, contents, $editable) {
                        theContent.agenda[$(this).attr('id').split('-')[1]].content = contents;
                    });
                }
            });
        },

        // toggleContentEditing
        toggleContentEditing: function(swtch) {

            this.contentAdding = false;

            this.contentEditing = swtch;
            theContent = this.content;

            if (! theContent.agenda) {
                theContent.agenda = [{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''},{time:null,content:''}];
            }

            if(swtch) {
                this.$nextTick(function () {
                    contentBody = $('#content-body').summernote({
                        height: 300,
                        toolbar: [
                            ['style', ['bold', 'italic', 'underline', 'clear']],
                            ['fontsize', ['fontsize']],
                            ['color', ['color']],
                            ['para', ['ul', 'ol', 'paragraph','link']],
                            ['view', ['codeview']]
                        ],
                        onPaste: function (e) {
                            var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                            e.preventDefault();
                            document.execCommand('insertText', false, bufferText);
                        }
                    }).on('summernote.change', function(we, contents, $editable) {
                        theContent.body = contents;
                    });
    
                    contentExtra = $('#content-extra').summernote({
                        height: 100,
                        toolbar: [
                            ['style', ['bold', 'italic', 'underline', 'clear']],
                            ['color', ['color']],
                            ['para', ['ul', 'ol', 'paragraph','link']],
                            ['view', ['codeview']]
                        ]
                    }).on('summernote.change', function(we, contents, $editable) {
                        theContent.extra = contents;
                    });
                    
                    for (var i = 0; i < 20; i++) {
                        $('#agenda-'+i).summernote({
                            height: 200,
                            toolbar: [
                                ['style', ['bold', 'italic', 'underline', 'clear']],
                                ['fontsize', ['fontsize']],
                                ['color', ['color']],
                                ['para', ['ul', 'ol', 'paragraph','link']],
                                ['view', ['codeview']]
                            ],
                            onPaste: function (e) {
                                var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                                e.preventDefault();
                                document.execCommand('insertText', false, bufferText);
                            }
                        }).on('summernote.change', function(we, contents, $editable) {
                            theContent.agenda[$(this).attr('id').split('-')[1]].content = contents;
                        });
                    }
                });
            }
        },

        // update Content
        updateContent:  function(content) {
            this.$set('loading.updating_content', true); // show loading spinner

            var errors = [];

            // Prep content info for sending to API for updating.
            var content = {
                name: this.content.name,
                heading: this.content.heading,
                subheading: this.content.subheading,
                body: this.content.body,
                extra: this.content.extra,
                agenda: this.content.agenda,
                image: this.content.image,
                link: this.content.link,
                embed: this.content.embed,
                download: this.content.download,
                date: this.content.date,
                type: this.content.type,
                active: this.content.active,
                status: this.content.status,
            };

            if(errors.length > 0)
            {
                this.$set('loading.updating_content', false); // hide loading spinner
                this.$set('errorBag', errors);
            } else {

                // Add content.
                if(this.contentAdding)
                {
                    // Update content.
                    this.$http.post(this.api_url+'/events/'+this.event.id+'/content/', content, function(response) {

                        this.$set('content', response.content);
                        
                        if (this.$$.contentImageInput.files.length || this.$$.contentDownloadInput.files.length || (this.$$.contentVideoInput && this.$$.contentVideoInput.files.length)) {
                            var formData = new FormData();

                            if (this.$$.contentImageInput.files.length) {
                                formData.append('image', this.$$.contentImageInput.files[0]);
                            }
                            if (this.$$.contentDownloadInput.files.length) {
                                formData.append('download', this.$$.contentDownloadInput.files[0]);
                            }
                            if (this.$$.contentVideoInput && this.$$.contentVideoInput.files.length) {
                                formData.append('video', this.$$.contentVideoInput.files[0]);
                            }

                            this.$http.post(this.api_url+'/events/'+this.event.id+'/content/'+this.content.id+'/upload', formData, function(image_response) {
                                window.location.replace('#'+this.event.id+'/content');
                                window.location.reload();
                            }).error((data, status, response) => {
                                if(status == 422) {
                                    this.$set('loading.updating_content', false); // hide loading spinner
                                    this.$set('errorBag', data.errors);
                                }
                            });
                        } else {
                            window.location.replace('#'+this.event.id+'/content');
                            window.location.reload();
                        }
                        
                    }).error((data, status, response) => {
                        if(status == 422) {
                            this.$set('loading.updating_content', false); // hide loading spinner
                            this.$set('errorBag', data.errors);
                        }
                    });

                } else {

                    // Update content.
                    this.$http.put(this.api_url+'/events/'+this.event.id+'/content/'+this.content.id, content, function(response) {

                        this.$set('content', response.content);

                        // if an image was specified; upload it and store it
                        if (this.$$.contentImageInput.files.length || this.$$.contentDownloadInput.files.length || (this.$$.contentVideoInput && this.$$.contentVideoInput.files.length)) {

                            var formData = new FormData();
                            
                            if (this.$$.contentImageInput.files.length) {
                                formData.append('image', this.$$.contentImageInput.files[0]);
                            }
                            if (this.$$.contentDownloadInput.files.length) {
                                formData.append('download', this.$$.contentDownloadInput.files[0]);
                            }
                            if (this.$$.contentVideoInput && this.$$.contentVideoInput.files.length) {
                                formData.append('video', this.$$.contentVideoInput.files[0]);
                            }

                            this.$http.post(this.api_url+'/events/'+this.event.id+'/content/'+this.content.id+'/upload', formData, function(image_response) {
                                window.location.replace('#'+this.event.id+'/content');
                                window.location.reload();

                            }).error((data, status, response) => {
                                if(status == 422) {
                                    this.$set('loading.updating_content', false); // hide loading spinner
                                    this.$set('errorBag', data.errors);
                                }
                            });
                        } else {
                            window.location.replace('#'+this.event.id+'/content');
                            window.location.reload();
                        }
                        
                    }).error((data, status, response) => {
                        if(status == 422) {
                            this.$set('loading.updating_content', false); // hide loading spinner
                            this.$set('errorBag', data.errors);
                        }
                    });

                }

            }
        },

        removeContentDownload: function (content) {
            if (window.confirm('Are you sure you wish to remove this file?')) {
                this.content.download = null;
            }
        },

        removeContentImage: function (content) {
            if (window.confirm('Are you sure you wish to remove this image?')) {
                this.content.image = null;
            }
        },

        // remove content from the event
        removeContent: function(content) {

            if( confirm("Are you sure you want to permanently remove this field?") == true ){
                this.$http.delete(this.api_url+'/events/'+this.event.id+'/content/'+content.id, function(response) {
                    this.$set('event',response.event);
                }); 
                return true;
            } else {
                return false;
            }

        },

        // Get event faq info
        getFaq: function(faq) {
            this.faqAdding = false;
            this.faqEditing = false;
            this.$set('faq', faq);
        },

        sortableFaq: function() {

          thisIsThis = this;

          $('#event_faqs tbody').sortable({
              placeholder: "sort",
              handle: '.fa-bars',
              update: () => {
                data = $('#event_faqs tbody').sortable('toArray','serialize');
                // Update order.
                thisIsThis.$http.post(thisIsThis.api_url+'/events/'+thisIsThis.event.id+'/faqs/sort', JSON.stringify(data), function(response) {
                    console.log(response);
                });
              }
            });

            $('#event_faqs tbody').disableSelection();
        },

        // add FAQ
        addEventFaq: function() {

            this.faqEditing = false;
            this.faqAdding = true;

            this.$set('faq', {
                heading: '',
                title: '',
                body: '',
                image: '',
                active: true
            });

            theFaq = this.faq;

            $('#faq-body').summernote({
                height: 200,
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph','link']],
                    ['view', ['codeview']]
                ]
            }).on('summernote.change', function(we, faqs, $editable) {
                theFaq.body = faqs;
            });
        },

        // toggleFaqEditing
        toggleFaqEditing: function(swtch) {

            this.$set('faqAdding', false);
            this.$set('faqEditing', swtch);

            theFaq = this.faq;

            if(swtch) {

                $('#faq-body').summernote({
                    height: 200,
                    toolbar: [
                        ['style', ['bold', 'italic', 'underline', 'clear']],
                        ['color', ['color']],
                        ['para', ['ul', 'ol', 'paragraph','link']],
                        ['view', ['codeview']]
                    ]
                }).on('summernote.change', function(we, faqs, $editable) {
                    theFaq.body = faqs;
                });

            }
        },

        // update Faq
        updateFaq: function(faq) {
            this.$set('loading.updating_faq', true); // show loading spinner

            var errors = [];

            // Prep faq info for sending to API for updating.
            var faq = {
                heading: this.faq.heading,
                title: this.faq.title,
                body: this.faq.body,
                active: this.faq.active,
            };

            if(errors.length > 0)
            {
                this.$set('loading.updating_faq', false); // hide loading spinner
                this.$set('errorBag', errors);
            } else {

                // Add faq.
                if(this.faqAdding)
                {

                    // Update faq.
                    this.$http.post(this.api_url+'/events/'+this.event.id+'/faqs/', faq, function(response) {

                        window.location.replace('#'+response.event.id+'/content');
                        window.location.reload();
                    }).error((data, status, response) => {
                        if(status == 422) {
                            this.$set('loading.updating_faq', false); // hide loading spinner
                            this.$set('errorBag', data.errors);
                        }
                    });

                } else {

                    // Update faq.
                    this.$http.put(this.api_url+'/events/'+this.event.id+'/faqs/'+this.faq.id, faq, function(response) {
                        window.location.replace('#'+response.event.id+'/content');
                        window.location.reload();
                    }).error((data, status, response) => {
                        if(status == 422) {
                            this.$set('loading.updating_faq', false); // hide loading spinner
                            this.$set('errorBag', data.errors);
                        }
                    });

                }

            }
        },

        // remove faq from the event
        removeFAQ: function(faq) {
            
            if( confirm("Are you sure you want to permanently remove this faq?") == true ){
                this.$http.delete(this.api_url+'/events/'+this.event.id+'/faqs/'+faq.id, function(response) {
                    this.$set('event',response.event);
                });
                return true;
            } else {
                return false;
            }

        },

        // Get event sponsor info
        getSponsor: function(sponsor) {
            this.sponsorAdding = false;
            this.sponsorEditing = false;
            this.$set('sponsor', sponsor);
        },

        sortableSponsor: function() {

            thisIsThis = this;

            $('#event_sponsors tbody').sortable({
                placeholder: "sort",
                handle: '.fa-bars',
                update: () => {
                    data = $('#event_sponsors tbody').sortable('toArray','serialize');
                    // Update order.
                    thisIsThis.$http.post(thisIsThis.api_url+'/events/'+thisIsThis.event.id+'/sponsors/sort', JSON.stringify(data), function(response) {
                        console.log(response);
                    });
                }
            });

            $('#event_sponsors tbody').disableSelection();
        },

        // add Sponsor
        addEventSponsor: function() {

            this.sponsorEditing = false;
            this.sponsorAdding = true;

            this.$set('sponsor', {
                name: '',
                link: '',
                image: '',
                active: true
            });

            theSponsor = this.sponsor;
        },

        // toggleSponsorEditing
        toggleSponsorEditing: function(swtch) {

            this.$set('sponsorAdding', false);
            this.$set('sponsorEditing', swtch);

            theSponsor = this.sponsor;

        },

        // update Sponsor
        updateSponsor: function(sponsor) {
            this.$set('loading.updating_sponsor', true); // show loading spinner

            var errors = [];

            // Prep sponsor info for sending to API for updating.
            var sponsor = {
                name: this.sponsor.name,
                link: this.sponsor.link,
                active: this.sponsor.active,
            };

            if(errors.length > 0)
            {
                this.$set('loading.updating_sponsor', false); // hide loading spinner
                this.$set('errorBag', errors);
            } else {

                // Add sponsor.
                if(this.sponsorAdding)
                {

                    // Update sponsor.
                    this.$http.post(this.api_url+'/events/'+this.event.id+'/sponsors/', sponsor, function(response) {

                        // if an image was specified; upload it and store it
                        var formData = new FormData();

                        formData.append('image', this.$$.contentImageInput3.files[0]);

                        this.$http.post(this.api_url+'/events/'+this.event.id+'/sponsors/'+response.sponsor.id+'/upload', formData, function(image_response) {
                            this.$set('loading.updating_sponsor', false); // remove loading spinner
                            this.$set('sponsor_updated', response.message);
                            this.$set('errorBag', null);
                            this.$set('content', image_response.content);
                            this.$set('sponsorAdding', false);
                            $('.alert-dismissible').delay(2000).fadeOut();
                        }).error((data, status, response) => {
                            if(status == 422) {
                                this.$set('loading.updating_sponsor', false); // hide loading spinner
                                this.$set('errorBag', data.errors);
                            }
                        });

                        window.location.replace('#'+response.event.id+'/content');
                        window.location.reload();
                    }).error((data, status, response) => {
                        if(status == 422) {
                            this.$set('loading.updating_sponsor', false); // hide loading spinner
                            this.$set('errorBag', data.errors);
                        }
                    });

                } else {

                    // Update sponsor.
                    this.$http.put(this.api_url+'/events/'+this.event.id+'/sponsors/'+this.sponsor.id, sponsor, function(response) {

                        // if an image was specified; upload it and store it
                        var formData = new FormData();

                        formData.append('image', this.$$.contentImageInput3.files[0]);

                        this.$http.post(this.api_url+'/events/'+this.event.id+'/sponsors/'+this.sponsor.id+'/upload', formData, function(image_response) {
                            this.$set('loading.updating_sponsor', false); // remove loading spinner
                            this.$set('sponsor_updated', response.message);
                            this.$set('errorBag', null);
                            this.$set('content', image_response.content);
                            this.$set('sponsorAdding', false);
                            $('.alert-dismissible').delay(2000).fadeOut();
                        }).error((data, status, response) => {
                            if(status == 422) {
                                this.$set('loading.updating_sponsor', false); // hide loading spinner
                                this.$set('errorBag', data.errors);
                            }
                        });

                        window.location.replace('#'+response.event.id+'/content');
                        window.location.reload();
                    }).error((data, status, response) => {
                        if(status == 422) {
                            this.$set('loading.updating_sponsor', false); // hide loading spinner
                            this.$set('errorBag', data.errors);
                        }
                    });

                }

            }
        },

        // remove sponsor from the event
        removeSponsor: function(sponsor) {
            
            if( confirm("Are you sure you want to permanently remove this sponsor?") == true ){
                this.$http.delete(this.api_url+'/events/'+this.event.id+'/sponsors/'+sponsor.id, function(response) {
                    this.$set('event',response.event);
                });
                return true;
            } else {
                return false;
            }

        },

         // Get event speaker info
        getSpeaker: function(speaker) {
            this.speakerAdding = false;
            this.speakerEditing = false;
            this.$set('speaker', speaker);
        },

        sortableSpeaker: function() {

        thisIsThis = this;

        $('#event_speakers tbody').sortable({
            placeholder: "sort",
            handle: '.fa-bars',
            update: () => {
                data = $('#event_speakers tbody').sortable('toArray','serialize');
                // Update order.
                thisIsThis.$http.post(thisIsThis.api_url+'/events/'+thisIsThis.event.id+'/speakers/sort', JSON.stringify(data), function(response) {
                    console.log(response);
                });
            }
            });

            $('#event_speakers tbody').disableSelection();
        },

        // add Speaker
        addEventSpeaker: function() {

            this.speakerEditing = false;
            this.speakerAdding = true;

            this.$set('speaker', {
                name: '',
                title: '',
                body: '',
                image: '',
                active: true
            });

            theSpeaker = this.speaker;

            $('#speaker-body').summernote({
                height: 200,
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph','link']],
                    ['view', ['codeview']]
                ],
                onPaste: function (e) {
                    var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                    e.preventDefault();
                    document.execCommand('insertText', false, bufferText);
                }
            }).on('summernote.change', function(we, speakers, $editable) {
                theSpeaker.body = speakers;
            });
        },

        // toggleSpeakerEditing
        toggleSpeakerEditing: function(swtch) {

            this.$set('speakerAdding', false);
            this.$set('speakerEditing', swtch);

            theSpeaker = this.speaker;

            if(swtch) {

                $('#speaker-body').summernote({
                    height: 200,
                    toolbar: [
                        ['style', ['bold', 'italic', 'underline', 'clear']],
                        ['color', ['color']],
                        ['para', ['ul', 'ol', 'paragraph','link']],
                        ['view', ['codeview']]
                    ],
                    onPaste: function (e) {
                        var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                        e.preventDefault();
                        document.execCommand('insertText', false, bufferText);
                    }
                }).on('summernote.change', function(we, speakers, $editable) {
                    theSpeaker.body = speakers;
                });

            }
        },

        // update Speaker
        updateSpeaker: function(speaker) {
            this.$set('loading.updating_speaker', true); // show loading spinner

            var errors = [];

            // Prep speaker info for sending to API for updating.
            var speaker = {
                name: this.speaker.name,
                title: this.speaker.title,
                body: this.speaker.body,
                active: this.speaker.active,
            };

            if(errors.length > 0)
            {
                this.$set('loading.updating_speaker', false); // hide loading spinner
                this.$set('errorBag', errors);
            } else {

                // Add speaker.
                if(this.speakerAdding)
                {

                    // Update speaker.
                    this.$http.post(this.api_url+'/events/'+this.event.id+'/speakers/', speaker, function(response) {

                        // if an image was specified; upload it and store it
                        var formData = new FormData();

                        formData.append('image', this.$$.contentImageInput2.files[0]);

                        this.$http.post(this.api_url+'/events/'+this.event.id+'/speakers/'+response.speaker.id+'/upload', formData, function(image_response) {
                            this.$set('loading.updating_speaker', false); // remove loading spinner
                            this.$set('speaker_updated', response.message);
                            this.$set('errorBag', null);
                            this.$set('content', image_response.content);
                            this.$set('speakerAdding', false);
                            $('.alert-dismissible').delay(2000).fadeOut();
                        }).error((data, status, response) => {
                            if(status == 422) {
                                this.$set('loading.updating_sponsor', false); // hide loading spinner
                                this.$set('errorBag', data.errors);
                            }
                        });

                        window.location.replace('#'+response.event.id+'/content');
                        window.location.reload();
                
                    }).error((data, status, response) => {
                        if(status == 422) {
                            this.$set('loading.updating_speaker', false); // hide loading spinner
                            this.$set('errorBag', data.errors);
                        }
                    });

                } else {

                    // Update speaker.
                    this.$http.put(this.api_url+'/events/'+this.event.id+'/speakers/'+this.speaker.id, speaker, function(response) {

                        // if an image was specified; upload it and store it
                        var formData = new FormData();

                        formData.append('image', this.$$.contentImageInput2.files[0]);

                        this.$http.post(this.api_url+'/events/'+this.event.id+'/speakers/'+this.speaker.id+'/upload', formData, function(image_response) {
                            this.$set('loading.updating_speaker', false); // remove loading spinner
                            this.$set('speaker_updated', response.message);
                            this.$set('errorBag', null);
                            this.$set('content', image_response.content);
                            this.$set('speakerAdding', false);
                            $('.alert-dismissible').delay(2000).fadeOut();
                        }).error((data, status, response) => {
                            if(status == 422) {
                                this.$set('loading.updating_speaker', false); // hide loading spinner
                                this.$set('errorBag', data.errors);
                            }
                        });

                        window.location.replace('#'+response.event.id+'/content');
                        window.location.reload();
                        
                    }).error((data, status, response) => {
                        if(status == 422) {
                            this.$set('loading.updating_speaker', false); // hide loading spinner
                            this.$set('errorBag', data.errors);
                        }
                    });

                }

            }
        },

        // remove speaker from the event
        removeSpeaker: function(speaker) {
            
            if( confirm("Are you sure you want to permanently remove this speaker?") == true ){
                this.$http.delete(this.api_url+'/events/'+this.event.id+'/speakers/'+speaker.id, function(response) {
                    this.$set('event', response.event);
                });
                return true;
            } else {
                return false;
            }

        },

        // Get event reports
        getReports: function(e) {
            e.preventDefault();
            this.$set('loading.reports', true); // show loading spinner

            request = {
                reports: this.reports
            }

            this.$http.post(this.api_url+'/reports/event/'+this.event.id, request, function(response) {
                this.loading.reports = false; // remove loading spinner
                window.location = response.url;
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        getBreakdown: function() {

            var url = this.api_url+'/reports/event/'+this.event.id+'/delegates/breakdown';

            this.$http.get(url, function(response) {

                if(this.breakdownChart) this.breakdownChart.destroy();
                $('#event-delegate-breakdown').parent().parent().children('ul.pie-legend').remove();

                var ctx = document.getElementById('event-delegate-breakdown').getContext('2d');

                var data = [];

                var i = 0; var total = 0;
                response.data.forEach(function(set) {
                    data.push({
                        value: set.value,
                        highlight: '#555555',
                        color: set.color,
                        label: set.label + ' ('+set.value+')'
                    });
                    total = total + set.value;
                    i++;
                });

                if(total > 0)
                {
                    var eventDelegateBreakdownChart = new Chart(ctx).Doughnut(data, {
                        responsive: true,
                        maintainAspectRatio: true,
                        legendtemplate_id : '<ul class=\"<%=name.toLowerCase()%>-legend list-group\">' +
                            '<% for (var i=0; i<segments.length; i++){%><li class=\"list-group-item\">' +
                                '<span style=\"background-color:<%=segments[i].fillColor%>\"></span>' +
                                '<%if(segments[i].label){%><%=segments[i].label%> (<strong><%=segments[i].value%></strong>)<%}%>' +
                            '</li><%}%>' +
                        '</ul>'
                    });

                    $('#event-delegate-breakdown').parent().parent().append(eventDelegateBreakdownChart.generateLegend());
                    this.$set('breakdownChart', eventDelegateBreakdownChart);
                }

            });
        },

        getActivity: function() {

            var url = this.api_url+'/reports/event/'+this.event.id+'/delegates/activity';

            if(this.activity_search.from) url += '&from='+this.activity_search.from;
            if(this.activity_search.to) url += '&to='+this.activity_search.to;

            this.$http.get(url, function(response) {

                if(this.activityChart) this.activityChart.destroy();
                var ctx = document.getElementById('event-delegate-activity').getContext('2d');

                var colours = ['70,190,190', '250,180,90', '70,120,190'];
                var data = {
                    labels: response.data.labels,
                    datasets: []
                }

                var i = 0;
                response.data.sets.forEach(function(set) {
                    data.datasets.push({
                        label: set.label,
                        data: set.data,
                        strokeColor: 'rgba('+colours[i]+',1)',
                        fillColor: 'rgba('+colours[i]+',0)',
                        pointColor: 'rgba(255,255,255,1)',
                        pointStrokeColor: 'rgba('+colours[i]+',1)',
                        //pointHighlightFill: 'rgba(255,255,255,1)',
                    });
                    i++;
                });

                var chartData = new Chart(ctx).Line(data, {
                    scaleFontFamily: "'Source Sans Pro', 'Helvetica', 'Arial', sans-serif",
                    tooltipFontFamily: "'Source Sans Pro', 'Helvetica', 'Arial', sans-serif",
                    tooltipFontSize: 12,
                    tooltiptemplate_id: "<%if (label){%><%=label%>: <%}%><%= value %>",
                    multiTooltiptemplate_id: "<%= datasetLabel %>: <%= value %>",
                    scaleFontColor: "#333",
                    scaleFontStyle: "bold",
                    scaleFontSize: 10,
                    responsive: true,
                    maintainAspectRatio: true,
                    bezierCurveTension : 0,
                    scaleGridLineColor : "#CCC",
                    scaleLineColor : "#CCC",
                    scaleShowLabels: true,
                    datasetStrokeWidth: 2,
                    pointDotStrokeWidth: 2,
                    pointDotRadius : 3,
                    tooltipFontFamily: "'Source Sans Pro', 'Helvetica', 'Arial', sans-serif",
                    tooltipTitleFontFamily: "'Source Sans Pro', 'Helvetica', 'Arial', sans-serif",
                    legendtemplate_id : '<ul class=\"<%=name.toLowerCase()%>-legend list-group\">' +
                        '<% for (var i=0; i<datasets.length; i++){%><li class=\"list-group-item\">' +
                            '<span style=\"background-color: rgb(<%=datasets[i].strokeColor%>)\"></span>' +
                            '<%if(datasets[i].label){%><%=datasets[i].label%><%}%>' +
                        '</li><%}%>' +
                    '</ul>'
                });

                this.$set('activityChart', chartData);

            });

        },

        decodeHtml: function (html) {
            var txt = document.createElement("textarea");
            txt.innerHTML = html;
            return txt.value;
        },

        submitForm: function(e) {
            e.preventDefault();

            if(typeof this.editing !== 'undefined') {

                this.$http.put(this.api_url+'/events/'+this.editing.id, this.form, function(response) {
                    this.form = {
                        client_id: '',
                        name: '',
                        private: 1,
                        description: '',
                        terms: '',
                        template_id: 1,
                        slug: '',
                        directory: false,
                        cname: '',
                        colour: '',
                        status: 'Draft',
                        secondary_colour: '',
                        header_colour: '',
                        footer: '',
                        body_font: 'Open Sans',
                        heading_font: 'Open Sans',
                        registration_opens_at: '',
                        registration_closes_at: '',
                        updates_closes_at: '',
                        settings: ''
                    };
                    this.getEvents();
                    this.getEvent(response.event.id);
                    this.view = 'show';
                    this.$set('errorBag', null);
                    window.location.href = '#'+response.event.id;
                }).error((data, status, response) => {
                    if(status == 422) {
                        this.$set('errorBag', data.errors);
                    }
                });

            } else {

                var formData = {
                    client_id: this.form.client_id,
                    name: this.form.name,
                    private: this.form.private,
                    description: this.form.description,
                    terms: this.form.terms,
                    template_id: this.form.template_id,
                    slug: this.form.slug,
                    directory: this.form.directory,
                    status: this.form.status,
                    cname: this.form.cname,
                    colour: this.form.colour,
                    secondary_colour: this.form.secondary_colour,
                    header_colour: this.form.header_colour,
                    footer: this.form.footer,
                    body_font: this.form.body_font,
                    heading_font: this.form.heading_font,
                    registration_opens_at: this.form.registration_opens_at,
                    registration_closes_at: this.form.registration_closes_at,
                    updates_closes_at: this.form.updates_closes_at,
                    duplicate_event_id: this.form.duplicate_event_id,
                    settings: this.form.settings
                };

                this.$http.post(this.api_url+'/events', formData, function(response) {
                    this.form = {
                        client_id: '',
                        name: '',
                        description: '',
                        terms: '',
                        template_id: 1,
                        private: 1,
                        slug: '',
                        directory: false,
                        cname: '',
                        status: 'Draft',
                        colour: '',
                        secondary_colour: '',
                        header_colour: '',
                        footer: '',
                        body_font: 'Open Sans',
                        heading_font: 'Open Sans',
                        registration_opens_at: '',
                        registration_closes_at: '',
                        updates_closes_at: '',
                        duplicate_event_id: '',
                        settings: '',
                    };

                    this.getEvents();
                    this.getEvent(response.event.id);
                    this.view = 'show';
                    this.$set('errorBag', null);
                    window.location.href = '#'+response.event.id;
                }).error((data, status, response) => {
                    if(status == 422) {
                         this.$set('errorBag', data.errors);
                    }
                });
            }
        }

    }
});
