Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

Vue.directive('date', {
    twoWay: true,
    bind: function () {
        var self = this;
        $(self.el).change(function() {
            var value = $(this).val();
            self.set(value);
        });
    },
    unbind: function () {
        var self = this;
    }
});

new Vue({
    el: '#dashboard',

    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),
        notifications: [],
        events: [],
        emails: [],
    },

    computed: {
        new: function() {
            return this.notifications.filter(this.filters.unseen);
        }
    },

    ready: function() {
        this.getNotifications();
        this.getEvents();
        this.getEmails();
        this.getActivity();
        var self = this;
        window.setInterval(function() {
            self.getNotifications();
        }, 30000); // We'll check for new notifications every 30 seconds
    },

    methods: {
        getNotifications: function() {
            this.$http.get(this.api_url+'/notifications', function(response) {
                this.notifications = response.notifications;
            }).error((data, status, response) => {
                console.log(response);
            });
        },

        seeNotifications: function() {
            this.$http.put(this.api_url+'/notifications', function(response) {
                this.notifications.forEach(function(notification) {
                    notification.seen = true;
                });
            }).error((data, status, response) => {
                console.log(response);
            });
        },

        // Get events
        getEvents: function() {
            this.$set('loading.events', true); // show loading spinner
            this.$http.get(this.api_url+'/events?&active=1&open=true&per_page=10', function(response) {
                this.loading.events = false; // remove loading spinner
                this.$set('events', response.events);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        // Get emails
        getEmails: function() {
            this.$set('loading.emails', true); // show loading spinner
            this.$http.get(this.api_url+'/events/emails/scheduled', function(response) {
                this.loading.emails = false; // remove loading spinner
                this.$set('emails', response.emails);
                response.emails.forEach(function(email) {
                    $(".countdown").countdown(email.scheduled_at, function(event) {
                        $(this).text(
                            event.strftime('%D days %H hours %M minutes')
                        );
                    });
                });

                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        getActivity: function() {

            var url = this.api_url+'/reports/events/delegates/activity';

            this.$http.get(url, function(response) {

                if(this.activityChart) this.activityChart.destroy();

                var ctx = document.getElementById('delegate-activity').getContext('2d');

                var colours = ['70,190,190', '250,180,90', '70,120,190'];
                var data = {
                    labels: response.data.labels,
                    datasets: []
                }

                var i = 0;
                response.data.sets.forEach(function(set) {
                    data.datasets.push({
                        label: set.label,
                        data: set.data,
                        strokeColor: 'rgba('+colours[i]+',1)',
                        fillColor: 'rgba('+colours[i]+',0)',
                        pointColor: 'rgba(255,255,255,1)',
                        pointStrokeColor: 'rgba('+colours[i]+',1)',
                        //pointHighlightFill: 'rgba(255,255,255,1)',
                    });
                    i++;
                });

                var chartData = new Chart(ctx).Line(data, {
                    scaleFontFamily: "'Source Sans Pro', 'Helvetica', 'Arial', sans-serif",
                    tooltipFontFamily: "'Source Sans Pro', 'Helvetica', 'Arial', sans-serif",
                    tooltipFontSize: 12,
                    tooltiptemplate_id: "<%if (label){%><%=label%>: <%}%><%= value %>",
                    multiTooltiptemplate_id: "<%= datasetLabel %>: <%= value %>",
                    scaleFontColor: "#333",
                    scaleFontStyle: "bold",
                    scaleFontSize: 10,
                    responsive: true,
                    maintainAspectRatio: true,
                    bezierCurveTension : 0,
                    scaleGridLineColor : "#CCC",
                    scaleLineColor : "#CCC",
                    scaleShowLabels: true,
                    datasetStrokeWidth: 2,
                    pointDotStrokeWidth: 2,
                    pointDotRadius : 3,
                    tooltipFontFamily: "'Source Sans Pro', 'Helvetica', 'Arial', sans-serif",
                    tooltipTitleFontFamily: "'Source Sans Pro', 'Helvetica', 'Arial', sans-serif",
                    legendtemplate_id : '<ul class=\"<%=name.toLowerCase()%>-legend list-group\">' +
                        '<% for (var i=0; i<datasets.length; i++){%><li class=\"list-group-item\">' +
                            '<span style=\"background-color: rgb(<%=datasets[i].strokeColor%>)\"></span>' +
                            '<%if(datasets[i].label){%><%=datasets[i].label%><%}%>' +
                        '</li><%}%>' +
                    '</ul>'
                });

                //$('#delegate-activity').parent().parent().append(chartData.generateLegend());
                this.$set('activityChart', chartData);

            });

        },
    }
});
