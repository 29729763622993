Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

Vue.directive('date', {
    twoWay: true,
    bind: function () {
        var self = this;
        $(self.el).change(function() {
            var value = $(this).val();
            self.set(value);
        });
    },
    unbind: function () {
        var self = this;
    }
});

new Vue({

    el: '#venues',
    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),

        filter: '',
        orderBy: 'name',
        sortBy: 'asc',

        view: 'index',

        // search model
        search: {
            keywords: ''
        },

        form: {
            name: '',
            email: '',
            phone: '',
            addr_1: '',
            addr_2: '',
            addr_3: '',
            addr_city: '',
            addr_county: '',
            addr_country: '',
            addr_postcode: '',
            description : '',
            supplier_id: '',
        },

        newContact: {
            firstname: '',
            surname: '',
            email: '',
            venue_id: 0
        },

        venues: []
    },

    ready: function() {
        this.route();
        var self = this;
        window.onhashchange = function(e) {
            self.route();
        };
    },

    methods: {
        route: function() {
            this.$set('loading.view', true);

            var hash = (window.location.hash.substr(1)).split('/');

            // index
            if(! hash[0]) {
                this.view = 'index';
                this.getVenues();
                return;
            }

            // show
            if(hash.length === 1 && ! isNaN(hash[0])) {
                this.view = 'show';
                this.getVenue(hash[0]);
                return;
            }

            // add
            if(hash.length === 1 && hash[0] == 'add') {
                this.loading.view = false;
                this.view = 'form';
                if(typeof this.errors !== 'undefined') {
                    this.errors.venue = null;
                }
            }

            // edit
            if(hash.length === 2 && hash[1] == 'edit') {
                this.view = 'form';
                if(typeof this.errors !== 'undefined') {
                    this.errors.venue = null;
                }
                if(this.venues.length) {
                    var venue = this.venues.filter(function(venue) {
                        return venue.id == hash[0];
                    });

                    if(venue.length) {
                        this.$set('form', venue[0]);
                        this.$set('editing', JSON.parse(JSON.stringify(venue[0])));
                    }

                    this.loading.view = false;
                }
                else {
                    this.$http.get(this.api_url+'/venues/'+hash[0], function(response) {
                        this.loading.view = false;
                        this.$set('form', response.venue);
                        this.$set('editing', JSON.parse(JSON.stringify(response.venue)));
                    }).error((data, status, response) => {
                        console.log(response);
                    });
                }
            }
        },

        // Add venue contact
        addContact: function(e) {
            e.preventDefault();
            // validate
            var contact = this.newcontact;
            this.$http.post(this.api_url+'/contacts', contact, function(response) {
                this.contacts.push(response.contact);
                this.newcontact = { firstname: '', surname: '', email: '', role_id: 2, venue_id: this.venue.id }
                $('#addcontactform').modal('hide');
            }).error((data, status, response) => {
                if(status == 422) {
                     this.$set('errors.contact', data.errors);
                }
            });
        },

        showContactForm: function() {
            this.newcontact = {
                firstname: '',
                surname: '',
                email: '',
                venue_id: this.venue.id
            };
        },

        deleteContact: function(contact) {
            var conf = window.confirm('Are you sure you want to delete this venue contact?');

            if(conf) {
                this.$http.delete(this.api_url+'/contacts/'+contact.id, function(response) {
                    this.contacts.$remove(contact);
                }).error((data, status, response) => {
                    console.log(response);
                });
            }
        },

        // Get venues
        getVenues: function() {
            this.$http.get(this.api_url+'/venues?per_page=10', function(response) {
                this.loading.view = false;
                this.$set('venues', response.venues);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error((data, status, response) => {
                console.log(response);
            });
        },

        // Get a venue
        getVenue: function(id) {

            // Get venue info
            this.$http.get(this.api_url+'/venues/'+id, function(response) {
                this.loading.view = false;
                this.$set('venue', response.venue);
                this.$set('dates', response.venue.dates);
                this.$set('supplier', response.venue.supplier);
                this.$set('contacts', response.venue.contacts);
                console.log(response.venue.dates);
            }).error((data, status, response) => {
                console.log(response);
            });
        },

        // Delete a venue
        deleteVenue: function(venue) {
            var conf = window.confirm('Are you sure you want to delete this venue?');

            if(conf) {
                this.$http.delete(this.api_url+'/venues/'+venue.id, function(response) {
                    this.venues.$remove(venue);
                }).error((data, status, response) => {
                    console.log(response);
                });
            }
        },

        // Pagination
        page: function(dir) {
            this.loading.view = true; // show loading spinner

            if(dir === 'prev' || dir === 'next') {
                var url = dir === 'prev' ? this.pagination.prev_page_url : this.pagination.next_page_url;
            } else {
                var url = dir;
            }

            url += '&orderBy=' + this.orderBy + '&sortBy=' + this.sortBy;

            if(this.search.keywords) url += '&keywords='+this.search.keywords;

            this.$http.get(url, function(response) {
                this.loading.view = false;
                this.$set('venues', response.venues);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error((data, status, response) => {
                console.log(response);
            });
        },

        // Sort
        sort: function(orderBy) {
            this.loading.view = true; // show loading spinner

            url = this.api_url+'/venues?per_page=10';

            if(this.search.keywords) url += '&keywords='+this.search.keywords;

            this.sortBy = (this.orderBy == orderBy) ? 'desc' : 'asc';
            this.orderBy = orderBy;

            url += '&orderBy=' + orderBy + '&sortBy=' + this.sortBy;

            this.$http.get(url, function(response) {
                this.loading.view = false;
                this.$set('venues', response.venues);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error((data, status, response) => {
                console.log(response);
            });
        },

        // Search venues
        searchvenues: function(e) {
            e.preventDefault();

            this.loading.venues = true; // show loading spinner

            var url = this.api_url+'/venues?per_page=10';

            if(this.search.keywords) url += '&keywords='+this.search.keywords;

            this.$http.get(url, function(response) {
                this.loading.venues = false; // remove loading spinner
                this.venues = response.venues;
                if(typeof response.pagination !== 'undefined') {
                    this.pagination = response.pagination;
                }
            }).error((data, status, response) => {
                console.log(response);
            });
        },

        // Clear search
        clearSearch: function() {
            this.loading.venues = true; // show loading spinner

            this.$http.get(this.api_url+'/venues?per_page=10', function(response) {
                this.loading.venues = false; // remove loading spinner
                this.$set('venues', response.venues);
                this.search = {
                    location_id: 0,
                    keywords: ''
                };
                if(typeof response.pagination !== 'undefined') {
                    this.pagination = response.pagination;
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        submitForm: function(e) {
            e.preventDefault();

            if(typeof this.editing !== 'undefined') {

                this.$http.put(this.api_url+'/venues/'+this.editing.id, this.form, function(response) {
                    this.form = {
                        name: '',
                        email: '',
                        phone: '',
                        addr_1: '',
                        addr_2: '',
                        addr_3: '',
                        addr_city: '',
                        addr_county: '',
                        addr_country: '',
                        addr_postcode: '',
                        description : '',
                        supplier_id: '',
                    };
                    this.getVenues();
                    this.loading.view = true;
                    this.view = 'index';
                    window.location = '#';
                }).error((data, status, response) => {
                    if(status == 422) {
                         this.$set('errors.venue', data.errors);
                    }
                });
                return;
            }

            // add

            var formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('email', this.form.email);
            formData.append('phone', this.form.phone);
            formData.append('addr_1', this.form.addr_1);
            formData.append('addr_2', this.form.addr_2);
            formData.append('addr_3', this.form.addr_3);
            formData.append('addr_city', this.form.addr_city);
            formData.append('addr_county', this.form.addr_county);
            formData.append('addr_country', this.form.addr_country);
            formData.append('addr_postcode', this.form.addr_postcode);
            formData.append('description', this.form.description);

            this.$http.post(this.api_url+'/venues', formData, function(response) {
                this.form = {
                    name: '',
                    email: '',
                    phone: '',
                    addr_1: '',
                    addr_2: '',
                    addr_3: '',
                    addr_city: '',
                    addr_county: '',
                    addr_country: '',
                    addr_postcode: '',
                    description : '',
                    supplier_id: '',
                };
                this.getVenues();
                this.loading.view = true;
                this.view = 'index';
                window.location = '#';
            }).error((data, status, response) => {
                if(status == 422) {
                     this.$set('errors.venue', data.errors);
                }
            });
        }
    }
});
